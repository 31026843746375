import { ProductVariant } from "@medusajs/medusa"
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing"
import { EnrichedMedusaVariant } from "gatsby-node"
import { getMinimumPrice } from "./getMinimumPrice"
import { getMaximumPrice } from "./getMaximumPrice"

export type GetSizePickerVariant =
  | "single"
  | "named-list"
  | "named-inline-list"
  | "inline-list"
  | "wrapped-inline-list"
  | "list"
  | null

export const getSizePickerVariant = (
  variants: ProductVariant[] | PricedVariant[] | EnrichedMedusaVariant[],
  isGiftcard?: boolean,
  isGiftSet?: boolean,
  isLoading?: boolean
): GetSizePickerVariant => {
  if (!variants?.length || !variants?.[0]?.sku) {
    return null
  }

  if (isGiftSet) {
    if (isLoading) {
      return null
    }

    if (variants.length < 2) {
      return "single"
    }

    const min = getMinimumPrice(variants)
    const max = getMaximumPrice(variants)

    if (min === max) {
      return "inline-list"
    }

    return "list"
  }

  if (variants.length < 2) {
    return "single"
  }

  if (
    variants[0]?.sku.startsWith("TT-") ||
    variants[0]?.sku.startsWith("TWL-")
  ) {
    return "named-list"
  }

  if (
    variants[0]?.sku.startsWith("BTK-") ||
    variants[0]?.sku.startsWith("SWSET-") ||
    variants[0]?.sku.startsWith("DN-BCBL-") ||
    variants[0]?.sku.startsWith("DN-BCHS-") ||
    variants[0]?.sku.startsWith("DNS-") ||
    variants[0]?.sku.startsWith("SSK-187-")
  ) {
    return "named-inline-list"
  }

  if (
    variants[0]?.sku.startsWith("SW") ||
    variants[0]?.sku.startsWith("BT-") ||
    variants[0]?.sku.startsWith("BH-") ||
    variants[0]?.sku.startsWith("CB-") ||
    variants[0]?.sku.startsWith("TSH-") ||
    variants[0]?.sku.startsWith("TS-") ||
    variants[0]?.sku.startsWith("KAF-") ||
    variants[0]?.sku.startsWith("LTSH-") ||
    variants[0]?.sku.startsWith("AU-RC-") ||
    variants[0]?.sku.startsWith("AU-DJ-") ||
    variants[0]?.sku.startsWith("PPL-BXR-") ||
    variants[0]?.sku.startsWith("SLPNG-TS-SS-") ||
    variants[0]?.sku.startsWith("SLPNG-TS-LS-") ||
    isGiftcard
  ) {
    return "inline-list"
  }

  if (
    variants?.[0]?.sku?.startsWith("UJ-") ||
    variants?.[0]?.sku?.startsWith("NA-") ||
    variants?.[0]?.sku?.startsWith("SS-") ||
    variants?.[0]?.sku?.startsWith("AU-NCTP-") ||
    variants?.[0]?.sku?.startsWith("AU-NCNB-") ||
    variants?.[0]?.sku?.startsWith("AU-NS-")
  ) {
    return "wrapped-inline-list"
  }

  return "list"
}
